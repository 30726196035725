import { useState } from "react";

const Sidebar = (props: any) => {
    let handleNavFunc = props.handleNavFunc;
    const [collapse, setcollapse] = useState("");
    const toggleFunc = (val: any) => {
        const prevValue = collapse;
        if (prevValue === val)
            setcollapse("");
        else
            setcollapse(val);

    };
    return (
        <>
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => handleNavFunc("/dashboard")}>
                            <i className="icon-grid menu-icon"></i>
                            <span className="menu-title">Dashboard</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={collapse == "UserManager" ? "nav-link" : "nav-link"} data-bs-toggle={collapse == "UserManager" ? "collapse" : "collapse"}
                            onClick={() => toggleFunc("UserManager")} aria-expanded={collapse == "UserManager" ? "true" : "false"} >
                            <i className="icon-layout menu-icon"></i>
                            <span className="menu-title">
                                User Manager
                            </span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className={collapse == "UserManager" ? "collapse show" : "collapse"} id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" onClick={() => handleNavFunc("/addrole")}>Create Role</a></li>
                                <li className="nav-item"> <a className="nav-link" onClick={() => handleNavFunc("/adduser")}>Add User</a></li>
                                <li className="nav-item"> <a className="nav-link" onClick={() => handleNavFunc("/adduser")}>Assign Role</a></li>
                                <li className="nav-item"> <a className="nav-link" onClick={() => handleNavFunc("/assignpermission")}>Assign Permission</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className={collapse == "PackageMaster" ? "nav-link" : "nav-link"} data-bs-toggle={collapse == "PackageMaster" ? "collapse" : "collapse"}
                            onClick={() => toggleFunc("PackageMaster")} aria-expanded={collapse == "PackageMaster" ? "true" : "false"} >
                            <i className="icon-image menu-icon"></i>
                            <span className="menu-title">Package Master</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className={collapse == "PackageMaster" ? "collapse show" : "collapse"} id="ui-advanced">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/dragula.html">Region</a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/ui-features/clipboard.html">Country</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className={collapse == "FixedPackage" ? "nav-link" : "nav-link"} data-bs-toggle={collapse == "FixedPackage" ? "collapse" : "collapse"}
                            onClick={() => toggleFunc("FixedPackage")} aria-expanded={collapse == "FixedPackage" ? "true" : "false"} >
                            <i className="icon-columns menu-icon"></i>
                            <span className="menu-title">
                                Fixed Package</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className={collapse == "FixedPackage" ? "collapse show" : "collapse"} id="form-elements">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"><a className="nav-link" href="pages/forms/basic_elements.html">Speciality Tours</a></li>
                                <li className="nav-item"><a className="nav-link" href="pages/forms/advanced_elements.html">Add Package</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className={collapse == "ModifyPackage" ? "nav-link" : "nav-link"} data-bs-toggle={collapse == "ModifyPackage" ? "collapse" : "collapse"}
                            onClick={() => toggleFunc("ModifyPackage")} aria-expanded={collapse == "ModifyPackage" ? "true" : "false"} >
                            <i className="icon-columns menu-icon"></i>
                            <span className="menu-title">
                                Modify Package</span>
                            <i className="menu-arrow"></i>
                        </a>
                        <div className={collapse == "ModifyPackage" ? "collapse show" : "collapse"} id="fixed">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"><a className="nav-link" href="pages/forms/basic_elements.html">Departure City</a></li>
                                <li className="nav-item"><a className="nav-link" href="pages/forms/advanced_elements.html">Departure Date</a></li>
                                <li className="nav-item"><a className="nav-link" href="pages/forms/validation.html">Cost</a></li>
                                <li className="nav-item"><a className="nav-link" href="pages/forms/wizard.html">Itinerary</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </nav>
        </>
    )
};
export default Sidebar


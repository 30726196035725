import Select from 'react-select';
const SelectField = ({
    name,
    className,
    classNamePrefix,
    data,
    isSearchable = true,
    isClearable = true,
    isDisabled = false,
    isLoading = true,
    isMulti = false,
    placeholder,
    selectedValue,
    onChange
}: any) => {
    return (
        <>
            <Select
                id={name}
                name={name}
                className={className}
                classNamePrefix={classNamePrefix}
                options={data}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isMulti={isMulti}
                placeholder={placeholder}
                value={data.find((data:any) => data.value === selectedValue)}
                onChange={onChange}
                defaultValue={selectedValue}
            />
        </>
    )
};
export default SelectField;

import './App.css';
import MainLayout from './features/layouts/MainLayout';
import withAuth from './auth/authwrapper';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <>
      <div className="container-scroller">
        <MainLayout />
      </div>
    </>
  );
}

export default withAuth()(App);
//export default App;

export default function Footer(props: any){

    return (
        <>
       <footer className="footer">
          <div className="d-sm-flex justify-content-center justify-content-sm-between">
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2023.   <a  target="_blank">Genius World</a>. All rights reserved.</span>
          </div>
        </footer>
        </>
    )
}

import { lazy, Suspense } from "react";
import Loader from "../components/loaders/loader";
import { Route, Routes } from "react-router-dom";

const Dashboard = lazy(() => import("../features/dashboard/dashboard"));
const PageNotFound = lazy(() => import("../features/page-not-found/pagenotfound"));
const Login = lazy(() => import("../features/login"));
const AddUser = lazy(() => import("../features/userManager/users/addUser"));
const AddRole = lazy(() => import("../features/userManager/roles/addRole"));
const AssignPermission = lazy(() => import("../features/userManager/permission/assignPermission"));

export default function Router({ value }: any) {
    return (
        <>
            <Suspense fallback={<div><Loader /></div>}>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/adduser' element={<AddUser />} />
                    <Route path='/addrole' element={<AddRole />} />
                    <Route path='/assignpermission' element={<AssignPermission />} />
                    <Route path='*' element={<PageNotFound />} />
                </Routes>
            </Suspense>
        </>
    )
}